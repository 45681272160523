<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue?: string[] | boolean;
    label?: string;
    name?: string;
    value?: string;
    required?: boolean;
    inputError?: boolean;
    inputErrorMessage?: string | Ref<string>;
  }>(),
  {
    required: false,
    inputError: false,
    inputErrorMessage: "",
    label: "",
    name: "",
    value: "",
  },
);

const { modelValue, value, label, inputError, inputErrorMessage, name } =
  toRefs(props);

const _value = computed(() => value.value ?? label.value);

const emit = defineEmits(["update:modelValue", "change"]);

const checked = computed<boolean>(() => {
  if (Array.isArray(modelValue.value))
    return modelValue.value.includes(_value?.value ?? "");
  return modelValue.value;
});

function onChange() {
  const newValue = Array.isArray(modelValue.value)
    ? checked.value
      ? modelValue.value.filter((x) => x != _value.value)
      : [...new Set([...modelValue.value, _value.value])] // ensure uniqueness
    : !checked.value;

  emit("update:modelValue", newValue);
  emit("change", newValue);
}
</script>

<template>
  <div>
    <label class="inline-flex items-start gap-2 cursor-pointer select-none">
      <!-- Hidden default checkbox -->
      <input
        type="checkbox"
        :name="name"
        class="hidden"
        :checked="checked"
        :required="required"
        @change="onChange"
      />
      <!-- Custom checkbox styling -->
      <div
        class="min-w-5 min-h-5 mt-1 flex items-center justify-center border rounded transition-colors duration-150"
        :class="[
          checked
            ? 'bg-scheppach-primary-500 border-scheppach-primary-700'
            : 'bg-transparent border-scheppach-primary-500',
        ]"
      >
        <!-- Checkmark or empty -->
        <svg
          v-if="checked"
          class="w-4 h-4 text-white"
          fill="none"
          stroke="currentColor"
          stroke-width="3"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <span>
        <span>{{ required ? "* " : "" }}</span>
        <span class="style-for-inline-v-html" v-html="label"></span>
      </span>
    </label>
    <span
      v-if="inputError && !!inputErrorMessage"
      class="pt-1 text-sm text-scheppach-error-500"
    >
      {{ inputErrorMessage }}
    </span>
  </div>
</template>
